* {
  transition: all 0.6 cubic-bezier(0.75, 0, 0.175, 1);
}

@keyframes fadeOutUnmount {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    padding: 0;
    height: 0;
  }
}

body[data-aos-easing="custom-easing"] [data-aos],
[data-aos][data-aos][data-aos-easing="custom-easing"] {
  transition-timing-function: cubic-bezier(0.75, 0, 0.175, 1);
}

.MuiGrid-root img {
  width: 100%;
}

.add-line {
  display: flex;
  align-items: baseline;
}

.add-line::after {
  content: "";
  flex-basis: 3rem;
  margin-left: 1rem;
  height: 1px;
  background-color: #000;
}

.add-line-left {
  position: relative;
}
.add-line-right {
  position: relative;
}

.add-line-left::before {
  content: "";
  height: 1px;
  width: 60px;
  position: absolute;
  top: calc(50% - 0.5px);
  right: calc(100% + 16px);
}
.add-line-left::after {
  content: "";
  position: absolute;
  height: 6px;
  width: 6px;
  background: transparent;
  top: calc(50% - 3px);
  right: calc(100% + 69.5px);
  rotate: 45deg;
  border: 1px solid;
  border-width: 0 0 1px 1px;
}

.add-line-right::after {
  content: "";
  height: 1px;
  width: 60px;
  position: absolute;
  top: calc(50% - 0.5px);
  left: calc(100% + 16px);
}
.add-line-right::before {
  content: "";
  position: absolute;
  height: 6px;
  width: 6px;
  background: transparent;
  top: calc(50% - 3px);
  left: calc(100% + 69.5px);
  rotate: 45deg;
  border: 1px solid;
  border-width: 1px 1px 0 0;
}
